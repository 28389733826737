<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${surgicals.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="$store.getters.getLogin.permissions.rha_surgicals.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="newSurgical"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form_data" lazy-validation>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Médico</th>
                            <th class="text-left">Ayudantes</th>
                            <th class="text-left">Anestesiologos</th>
                            <th class="text-left">Fecha cirugía</th>
                            <th class="text-left">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in surgicals" :key="i">
                            <td>
                              {{ item.doctor_name }}
                              {{ item.doctor_first_surname }}
                              {{ item.doctor_second_surname }}
                            </td>
                            <td>
                              <span
                                v-for="(
                                  itemAyudante, j
                                ) in item.rha_surgical_auxiliaries"
                                :key="j"
                              >
                                {{ itemAyudante.name_auxiliary }}
                                {{ itemAyudante.first_surname_auxiliary }}
                                {{ itemAyudante.second_surname_auxiliary }}
                                <br />
                              </span>
                            </td>
                            <td>
                              <span
                                v-for="(
                                  itemAnestesiologo, k
                                ) in item.rha_surgical_anesthesiologists"
                                :key="k"
                              >
                                {{ itemAnestesiologo.name_anesthesiologist }}
                                {{
                                  itemAnestesiologo.first_surname_anesthesiologist
                                }}
                                {{
                                  itemAnestesiologo.second_surname_anesthesiologist
                                }}
                                <br />
                              </span>
                            </td>
                            <td>
                              {{ item.admission_date }}
                              {{ item.admission_time }} -
                              {{ item.finished_date }} {{ item.finished_time }}
                            </td>
                            <td>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_surgicals.read
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="success"
                                    dark
                                    @click="viewDetail(item.id)"
                                  >
                                    <v-icon v-text="'mdi-eye-outline'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Ver'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_surgicals.update
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="editsurgical(item.id)"
                                  >
                                    <v-icon v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Editar'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_surgicals.delete
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="error"
                                    dark
                                    @click="HandleDesactivate(item.id)"
                                  >
                                    <v-icon v-text="'mdi-delete'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Eliminar'" />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- modal update photo -->
    <div>
      <v-dialog
        v-model="modal_add"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar dark>
            <v-btn icon dark @click="handleDialogAdd">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-text="'Agregar'" />
            <v-spacer />
            <v-toolbar-items>
              <v-btn dark text @click="handleSubmit">Aceptar</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form v-on:submit.prevent ref="form_data" lazy-validation>
                <v-row align="center" justify="center">
                  <v-col cols="12" xs="12" md="12">
                    <v-row>
                      <v-col
                        cols="12"
                        xs="12"
                        md="6"
                        v-for="(itemSurgical, k) in surgical"
                      >
                        <v-card class="mx-auto">
                          <v-card-title>
                            <h2
                              class="text-caption"
                              v-text="itemSurgical.title"
                            />
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="12"
                                xs="12"
                                md="12"
                                style="padding-bottom: 0px"
                              >
                                <span>Pulsa enter para buscar un médico.</span>
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Nombre del doctor*"
                                  v-model="itemSurgical.doctor_name"
                                  :rules="rules.text50Required"
                                  type="text"
                                  v-on:keyup.enter="searchDoctor(k)"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido paterno del doctor*"
                                  v-model="itemSurgical.doctor_first_surname"
                                  :rules="rules.text25Required"
                                  type="text"
                                  v-on:keyup.enter="searchDoctor(k)"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido materno del doctor*"
                                  v-model="itemSurgical.doctor_second_surname"
                                  :rules="rules.text25"
                                  type="text"
                                  v-on:keyup.enter="searchDoctor(k)"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-select
                                  label="Tipo de especialidad*"
                                  v-model="itemSurgical.specialty_type_id"
                                  :rules="rules.required"
                                  :items="specialty_types"
                                  item-value="id"
                                  :item-text="(item) => item.specialty_type"
                                  :loading="specialty_types_loading"
                                />
                              </v-col>
                            </v-row>

                            <v-divider></v-divider>
                            <h4>Ayudantes</h4>
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              size="x-small"
                              color="warning"
                              tLabel="Agregrar auxiliar"
                              tPosition="bottom"
                              :loading="loading"
                              @click="addAuxiliary(k)"
                            >
                              <v-icon medium v-text="'mdi-plus'" />
                            </v-btn>
                            <v-row
                              v-for="(
                                rha_surgical_auxiliarie, i
                              ) in itemSurgical.rha_surgical_auxiliaries"
                            >
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Nombre del ayudante"
                                  v-model="
                                    itemSurgical.rha_surgical_auxiliaries[i]
                                      .name_auxiliary
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido paterno del ayudante"
                                  v-model="
                                    itemSurgical.rha_surgical_auxiliaries[i]
                                      .first_surname_auxiliary
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido materno del ayudante"
                                  v-model="
                                    itemSurgical.rha_surgical_auxiliaries[i]
                                      .second_surname_auxiliary
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col md="1">
                                <v-btn
                                  class="ma-2"
                                  text
                                  icon
                                  size="small"
                                  color="red darken-2"
                                  tLabel="Quitar"
                                  tPosition="bottom"
                                  @click="deleteAuxiliary(k, i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h4>Anestesiólogos</h4>
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              size="x-small"
                              color="warning"
                              tLabel="Agregrar Anestesiólogos"
                              tPosition="bottom"
                              :loading="loading"
                              @click="addAnesthesiologist(k)"
                            >
                              <v-icon medium v-text="'mdi-plus'" />
                            </v-btn>
                            <v-row
                              v-for="(
                                rha_surgical_anesthesiologist, i
                              ) in itemSurgical.rha_surgical_anesthesiologists"
                            >
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Nombre del anestesiólogo"
                                  v-model="
                                    itemSurgical.rha_surgical_anesthesiologists[
                                      i
                                    ].name_anesthesiologist
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido paterno del anestesiólogo"
                                  v-model="
                                    itemSurgical.rha_surgical_anesthesiologists[
                                      i
                                    ].first_surname_anesthesiologist
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col cols="12" xs="12" md="3">
                                <v-text-field
                                  label="Apellido materno del anestesiólogo"
                                  v-model="
                                    itemSurgical.rha_surgical_anesthesiologists[
                                      i
                                    ].second_surname_anesthesiologist
                                  "
                                  :rules="rules.medico"
                                  type="text"
                                />
                              </v-col>
                              <v-col md="1">
                                <v-btn
                                  class="ma-2"
                                  text
                                  icon
                                  size="small"
                                  color="red darken-2"
                                  tLabel="Quitar"
                                  tPosition="bottom"
                                  @click="deleteAnesthesiologist(k, i)"
                                >
                                  <v-icon medium v-text="'mdi-close'" />
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h4>Datos de la cirugía</h4>
                            <v-menu
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="itemSurgical.admission_date"
                                  :rules="rules.required"
                                  label="Fecha de inicio cirugia*"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="itemSurgical.admission_date"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                                min="1950-01-01"
                              ></v-date-picker>
                            </v-menu>

                            <v-text-field
                              v-model="itemSurgical.admission_time"
                              label="Hora de inicio cirugia*"
                              :rules="rules.required"
                              type="time"
                            />

                            <v-textarea
                              label="Descripción de procedimiento"
                              v-model="itemSurgical.description"
                              value=""
                            />

                            <v-menu
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="itemSurgical.finished_date"
                                  :rules="rules.required"
                                  label="Fecha de término cirugia*"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="itemSurgical.finished_date"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                                min="1950-01-01"
                              ></v-date-picker>
                            </v-menu>

                            <v-text-field
                              v-model="itemSurgical.finished_time"
                              label="Hora de término cirugia*"
                              :rules="rules.required"
                              type="time"
                            />

                            <v-divider></v-divider>
                            <h4>Documentos</h4>
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              size="x-small"
                              color="warning"
                              tLabel="Agregrar documento"
                              tPosition="bottom"
                              :loading="loading"
                              @click="addDocumento(k)"
                            >
                              <v-icon medium v-text="'mdi-plus'" />
                            </v-btn>
                            <div
                              v-for="(document, i) in itemSurgical.documents"
                              :key="i"
                              v-if="document.active"
                            >
                              <v-autocomplete
                                label="Documento*"
                                v-model="itemSurgical.documents[i].document_id"
                                :rules="rules.type2"
                                :items="documents"
                                item-value="id"
                                :item-text="(item) => item.document"
                              />
                              <v-text-field
                                label="Descripción del documento"
                                v-model="itemSurgical.documents[i].description"
                                :rules="rules.surgicals2"
                                type="text"
                              />
                              <input
                                accept=".pdf, .docx, .doc, .txt, .xlsx, .xls"
                                label="Seleccione el documento"
                                type="file"
                                @change="select_file($event, k, i)"
                              /><v-btn
                                class="ma-2"
                                text
                                icon
                                size="small"
                                color="red darken-2"
                                tLabel="Quitar"
                                tPosition="bottom"
                                @click="deleteDocument(k, i)"
                              >
                                <v-icon medium v-text="'mdi-close'" />
                              </v-btn>
                              <v-divider></v-divider>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div>
      <v-dialog
        v-model="view"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar dark>
            <v-btn icon dark @click="handleDialogView">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  md="6"
                  v-for="(itemSurgical, k) in surgical"
                >
                  <v-card class="mx-auto">
                    <v-card-title>
                      <h2 class="text-caption" v-text="itemSurgical.title" />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Nombre del doctor'"
                        />
                        <br />
                        <span v-text="itemSurgical.doctor_name" />
                        <span v-text="itemSurgical.doctor_first_surname" />
                        <span v-text="itemSurgical.doctor_second_surname" />
                      </p>
                      <v-divider></v-divider>
                      <h4>Ayudantes</h4>
                      <p
                        v-for="(
                          item, i
                        ) in itemSurgical.rha_surgical_auxiliaries"
                      >
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Ayudante'"
                        />
                        <br />
                        <span v-text="item.name_auxiliary" />
                        <span v-text="item.first_surname_auxiliary" />
                        <span v-text="item.second_surname_auxiliary" />
                      </p>
                      <v-divider></v-divider>
                      <h4>Anestesiologos</h4>
                      <p
                        v-for="(
                          item, i
                        ) in itemSurgical.rha_surgical_anesthesiologists"
                      >
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Anestesiólogo'"
                        />
                        <br />
                        <span v-text="item.name_anesthesiologist" />
                        <span v-text="item.first_surname_anesthesiologist" />
                        <span v-text="item.second_surname_anesthesiologist" />
                      </p>
                      <v-divider></v-divider>
                      <h4>Datos de la cirugía</h4>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Fecha'"
                        />
                        <br />
                        <span v-text="itemSurgical.admission_date" />
                      </p>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Hora'"
                        />
                        <br />
                        <span v-text="itemSurgical.admission_time" />
                      </p>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Descripción'"
                        />
                        <br />
                        <span v-text="itemSurgical.description" />
                      </p>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Fecha'"
                        />
                        <br />
                        <span v-text="itemSurgical.finished_date" />
                      </p>
                      <p>
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Hora'"
                        />
                        <br />
                        <span v-text="itemSurgical.finished_time" />
                      </p>
                      <v-col
                        cols="12"
                        xs="12"
                        md="6"
                        v-for="(document, i) in itemSurgical.documents"
                        :key="i"
                      >
                        <v-card class="mx-auto">
                          <v-card-title>
                            <h2 class="text-caption" v-text="'DOCUMENTOS'" />
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <p>
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Tipo de documento'"
                              />
                              <br />
                              <span v-text="document.document_type.document" />
                            </p>
                            <p>
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Apellido paterno del doctor'"
                              />
                              <br />
                              <span v-text="document.description" />
                            </p>
                            <p>
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="warning"
                                    dark
                                    :href="
                                      url_documents +
                                      '/rha_surgicals/' +
                                      document.url
                                    "
                                    target="_blank"
                                  >
                                    <v-icon v-text="'mdi-download'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Descargar'" />
                              </v-tooltip>
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialog_doctor" width="800">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Seleccione un médico
          </v-card-title>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <td>Médico</td>
                    <td>Asignar</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(doctor, i) in doctors">
                    <td>
                      {{ doctor.name }} {{ doctor.first_surname }}
                      {{ doctor.second_surname }}
                    </td>
                    <td>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange darken-2"
                        @click="getDoctor(i)"
                      >
                        <v-icon dark> mdi-send </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_doctor = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
    </div>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      view: false,
      url_documents: URL_DOCUMENTS,
      page_route: null,
      page_title: "Notas quirúrgicas",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: true,
      data: {
        amount_2: 7000.0,
      },
      rules: {},
      documents: [],
      modal_add: false,
      surgicals: [],
      surgical: [
        {
          id: null,
          title: "NOTA QUIRÚRGICA DE MÉDICO",
          is_doctor_note: true,
          rha_surgical_auxiliaries: [],
          rha_surgical_anesthesiologists: [],
          rha_surgical_nurses: [],
          documents: [],
        },
        {
          id: null,
          title: "NOTA QUIRÚRGICA DE ENFERMERÍA",
          is_doctor_note: false,
          rha_surgical_auxiliaries: [],
          rha_surgical_anesthesiologists: [],
          rha_surgical_nurses: [],
          documents: [],
        },
      ],
      rha: {},
      rha_id: this.$attrs.id,
      specialty_types: [],
      specialty_types_loading: true,
      doctors: [],
      dialog_doctor: false,
      k: 0,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    select_file(event, k, i) {
      this.surgical[k].documents[i].file = event.target.files[0];
    },
    handleDialogView() {
      this.view = !this.view;
    },
    searchDoctor(k) {
      const search_doctor = {
        name: this.surgical[k].doctor_name,
        first_surname: this.surgical[k].doctor_first_surname,
        second_surname: this.surgical[k].doctor_second_surname,
      };
      Axios.post(
        URL_API + "/doctors/search",
        search_doctor,
        headersToken(this.login.token)
      ).then((response) => {
        this.doctors = response.data.data;
        this.k = k;
        if (response.data.data.length === 1) {
          this.surgical[k].doctor_name = response.data.data[0].name;
          this.surgical[k].doctor_first_surname =
            response.data.data[0].first_surname;
          this.surgical[k].doctor_second_surname =
            response.data.data[0].second_surname;
          this.surgical[k].specialty_type_id =
            response.data.data[0].specialty_type_id;
        } else {
          if (response.data.data.length === 0) this.search_fail = true;
          else {
            this.dialog_doctor = true;
          }
        }
      });
    },
    getDoctor(i) {
      this.surgical[this.k].doctor_name = this.doctors[i].name;
      this.surgical[this.k].doctor_first_surname =
        this.doctors[i].first_surname;
      this.surgical[this.k].doctor_second_surname =
        this.doctors[i].second_surname;
      this.surgical[this.k].specialty_type_id =
        this.doctors[i].specialty_type_id;
      this.dialog_doctor = false;
    },
    handleDialogAddDoctor() {
      this.doctor_add = !this.doctor_add;
    },
    getSurgicals() {
      index("rha/surgical", this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.surgicals = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
        this.modal_add = false;
      });
    },
    handleSubmit() {
      this.surgical[0].created_by_id = this.login.id;
      this.surgical[0].updated_by_id = this.login.id;
      this.surgical[0].rha_id = this.$attrs.id;
      this.surgical[1].created_by_id = this.login.id;
      this.surgical[1].updated_by_id = this.login.id;
      this.surgical[1].rha_id = this.$attrs.id;

      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              Axios.post(
                URL_API + "/rha/surgical",
                this.dataToDataForm(),
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.surgical = {
                    created_by_id: this.login.id,
                    updated_by_id: this.login.id,
                    rha_id: this.$attrs.id,
                    documents: [],
                    rha_surgical_auxiliaries: [],
                    rha_surgical_anesthesiologists: [],
                    rha_surgical_nurses: [],
                  };
                  this.getSurgicals();
                } else console.log(response.data.message);
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    dataToDataForm() {
      let data = new FormData();
      data.append("notes", JSON.stringify(this.surgical));
      for (var k = 0; k < 2; k++) {
        for (var i = 0; i < this.surgical[k].documents.length; i++) {
          for (var document_key in this.surgical[k].documents[i]) {
            if (document_key === "file")
              data.append(
                "document_file_" + k + "_" + i,
                this.surgical[k].documents[i][document_key]
              );
          }
        }
        data.append("total_documents_" + k, this.surgical[k].documents.length);
      }
      return data;
    },
    HandleDesactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rha/surgical", this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getSurgicals()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    editsurgical(id) {
      show("rha/surgical", this.login.token, id).then((response) => {
        this.surgical = response;
        this.surgical[0].title = "NOTA QUIRÚRGICA DE MÉDICO";
        this.surgical[1].title = "NOTA QUIRÚRGICA DE ENFERMERÍA";
        this.modal_add = true;
      });
    },
    viewDetail(id) {
      show("rha/surgical", this.login.token, id).then((response) => {
        this.surgical = response;
        this.surgical[0].title = "NOTA QUIRÚRGICA DE MÉDICO";
        this.surgical[1].title = "NOTA QUIRÚRGICA DE ENFERMERÍA";
        this.view = true;
      });
    },
    handleDialogAdd() {
      this.modal_add = !this.modal_add;
    },
    newSurgical() {
      this.modal_add = true;
      this.surgical = [
        {
          id: null,
          title: "NOTA QUIRÚRGICA DE MÉDICO",
          is_doctor_note: true,
          rha_surgical_auxiliaries: [],
          rha_surgical_anesthesiologists: [],
          rha_surgical_nurses: [],
          documents: [],
        },
        {
          id: null,
          title: "NOTA QUIRÚRGICA DE ENFERMERÍA",
          is_doctor_note: false,
          rha_surgical_auxiliaries: [],
          rha_surgical_anesthesiologists: [],
          rha_surgical_nurses: [],
          documents: [],
        },
      ];
    },
    addDocumento(k) {
      this.surgical[k].documents.push({
        id: null,
        desciption: "",
        document_id: 0,
        active: true,
        document_type: { document: "" },
      });
    },
    deleteDocument(k, i) {
      if (this.surgical[k].documents[i].id === null)
        this.surgical[k].documents.splice(i, 1);
      else this.surgical[k].documents[i].active = false;
    },
    addAuxiliary(k) {
      this.surgical[k].rha_surgical_auxiliaries.push({
        id: null,
        name_auxiliary: "",
        first_surname_auxiliary: "",
        second_surname_auxiliary: "",
        active: true,
      });
    },
    deleteAuxiliary(k, i) {
      if (this.surgical[k].rha_surgical_auxiliaries[i].id === null)
        this.surgical[k].rha_surgical_auxiliaries.splice(i, 1);
      else this.surgical[k].rha_surgical_auxiliaries[i].active = false;
    },
    addAnesthesiologist(k) {
      this.surgical[k].rha_surgical_anesthesiologists.push({
        id: null,
        name_anesthesiologist: "",
        first_surname_anesthesiologist: "",
        second_surname_anesthesiologist: "",
        active: true,
      });
    },
    deleteAnesthesiologist(k, i) {
      if (this.surgical[k].rha_surgical_anesthesiologists[i].id === null)
        this.surgical[k].rha_surgical_anesthesiologists.splice(i, 1);
      else this.surgical[k].rha_surgical_anesthesiologists[i].active = false;
    },
    validateHour(time) {
      const hours = time.split(":")[0];
      const minutes = time.split(":")[1];
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        this.$swal.fire(msgAlert("error", "Formato de hora incorrecto"));
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    index("documents/4", this.login.token, []).then((response) => {
      this.documents = response.data;
    });

    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types_loading = false;
    });
    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;
    });
    this.getSurgicals();
    this.rules = rules();
  },
};
</script>